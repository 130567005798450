import React from "react";

const Features = () => {
  return (
    <div>
      <section
        id="features"
        className="services-area services-bg pt-25 pb-20"
        style={{
          backgroundImage: "url(/img/shape/header-sape2.png)",
          backgroundPosition: "right top",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10">
              <div className="section-title text-center pl-40 pr-40 mb-45">
                <h2>Our Features</h2>
                <p>
                  Experience Seamless, Fast, and Secure Connectivity for Your
                  eCommerce Site with Our Solution.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-30">
              <div className="s-single-services active text-center">
                <div className="services-icon">
                  <img
                    src="/assets/img/icon/f-icon1.jpg"
                    width="192px"
                    height="auto"
                    objectfit="cover"
                  />
                </div>
                <div className="second-services-content">
                  <h5>Copy Link</h5>
                  <p>
                    Create products, sliders, banners, and coupons. Click to
                    copy the link and use it effortlessly into your site.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-30">
              <div className="s-single-services text-center">
                <div className="services-icon">
                  <img
                    src="/assets/img/icon/f-icon2.jpg"
                    width="100%"
                    height="192px"
                    objectfit="cover"
                  />
                </div>
                <div className="second-services-content">
                  <h5>Use Anywhere</h5>
                  <p>
                    Paste the copied link into your site by replacing your store
                    ID—your setup is complete!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-30">
              <div className="s-single-services text-center">
                <div className="services-icon">
                  <img
                    src="/assets/img/icon/f-icon3.jpg"
                    width="192px"
                    height="auto"
                    objectfit="cover"
                  />
                </div>
                <div className="second-services-content">
                  <h5>Sell Anything</h5>
                  <p>
                    Start receiving orders with payments via PayPal, Stripe, or
                    COD Securely into your Wallet is ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
