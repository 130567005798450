import React from "react";

const Contact = () => {
  return (
    <div>
      <section
        id="contact"
        className="contact-area contact-bg pt-50 pb-100 p-relative fix"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-img2">
                <img
                  src="/assets/img/bg/illustration.jpg"
                  width="100%"
                  height="auto"
                  alt="test"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title mb-40">
                <h2>Get In Touch</h2>
                <p>
                  Reach out for any questions or support. Our team is here to
                  help and ensure you have an exceptional experience.
                </p>
              </div>
              <form action="#" className="contact-form">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="contact-field p-relative c-name mb-20">
                      <input type="text" placeholder="Name" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="contact-field p-relative c-email mb-20">
                      <input type="text" placeholder="Email" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="contact-field p-relative c-subject mb-20">
                      <input type="text" placeholder="Phone" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="contact-field p-relative c-message mb-45">
                      <textarea
                        name="message"
                        id="message"
                        cols="10"
                        rows="10"
                        placeholder="Write comments"
                      ></textarea>
                    </div>
                    <button className="btn">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
