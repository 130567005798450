import React from "react";
import "./termsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="terms">
              <h1>Terms and Conditions</h1>
              <span>
                <strong>Effective Date:</strong> 29/8/2024
              </span>

              <h3>1. Introduction</h3>
              <p>
                Welcome to cartvelly.com (“we,” “our,” or “us”). These Terms and
                Conditions (“Terms”) govern your use of our website and services
                (“Platform”). By accessing or using our Platform, you agree to
                be bound by these Terms. If you do not agree to these Terms, you
                may not access or use the Platform.
              </p>

              <h3>2. User Accounts</h3>
              <p>
                To use certain features of our Platform, you may be required to
                create an account. You agree to provide accurate and up-to-date
                information during registration and to keep your account
                information current. You are responsible for maintaining the
                confidentiality of your account login credentials and for all
                activities that occur under your account.
              </p>

              <h3>3. Orders and Payments</h3>
              <p>
                By placing an order through our Platform, you agree to pay the
                full amount due for your purchase, including any applicable
                taxes and shipping fees. We reserve the right to cancel or
                refuse any order at our discretion.
              </p>

              <h3>4. Shipping and Delivery</h3>
              <p>
                We will make reasonable efforts to deliver your order within the
                estimated delivery time. However, delivery times are estimates
                and are not guaranteed. We are not responsible for any delays
                caused by third-party carriers or customs clearance processes.
              </p>

              <h3>5. Returns and Refunds</h3>
              <p>
                If you are not satisfied with your purchase, you may return the
                item in accordance with our return policy. Refunds will be
                issued to the original payment method used for the purchase. All
                return shipping costs are the responsibility of the customer,
                unless otherwise stated in our return policy.
              </p>

              <h3>6. Prohibited Activities</h3>
              <p>
                You agree not to engage in any of the following activities on
                our Platform:
              </p>
              <ul>
                <li>Using the Platform for any unlawful purpose</li>
                <li>Engaging in fraudulent or deceptive practices</li>
                <li>Attempting to interfere with or disrupt the Platform</li>
                <li>Violating the intellectual property rights of others</li>
                <li>Posting or transmitting harmful content</li>
              </ul>

              <h3>7. Intellectual Property</h3>
              <p>
                All content on our Platform, including text, graphics, logos,
                and images, is the property of cartvelly.com or its content
                suppliers and is protected by intellectual property laws. You
                may not use, reproduce, or distribute any content from our
                Platform without our prior written permission.
              </p>

              <h3>8. Limitation of Liability</h3>
              <p>
                To the fullest extent permitted by law, cartvelly.com shall not
                be liable for any indirect, incidental, special, or
                consequential damages arising out of or in connection with your
                use of the Platform. Our total liability to you for any claim
                arising from these Terms shall not exceed the amount paid by you
                for the specific service or product at issue.
              </p>

              <h3>9. Governing Law</h3>
              <p>
                These Terms and your use of the Platform are governed by and
                construed in accordance with the laws of [Your Jurisdiction].
                Any disputes arising out of or relating to these Terms shall be
                resolved in the courts of [Your Jurisdiction].
              </p>

              <h3>10. Changes to These Terms</h3>
              <p>
                We may update these Terms from time to time. Any changes will be
                posted on this page with an updated “Effective Date.” Your
                continued use of the Platform after any changes to these Terms
                constitutes your acceptance of the revised Terms.
              </p>

              <h3>11. Contact Us</h3>
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at:
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@cartvelly.com">info@cartvelly.com</a>
                <br />
                <strong>Address:</strong> cartvelly.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
