import React, { useState } from "react";

const Pricing = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const handleTabSwitch = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <section id="pricing" className="pricing-area pt-100 pb-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <div className="section-title text-center mb-50">
              <h2>Our Pricing Plans</h2>
              <p>
                Choose the plan that best fits your needs and start experiencing
                the benefits of our service today. Our flexible pricing options
                ensure you get the most value for your investment.
              </p>
            </div>
            <nav className="pricing-tab mb-60">
              <span
                className={`monthly_tab_title ${!isAnnual ? "active" : ""}`}
              >
                Monthly
              </span>
              <span
                className={`pricing-tab-switcher ${
                  isAnnual ? "switch-to-annual active" : "switch-to-monthly"
                }`}
                onClick={handleTabSwitch}
              ></span>
              <span className={`annual_tab_title ${isAnnual ? "active" : ""}`}>
                Annual
              </span>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="pricing-box text-center mb-60">
              <div className="pricing-head">
                <h4>Basic</h4>
                <div className="pricing-amount">
                  {isAnnual ? (
                    <div className="annual_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">49</span>
                      <span className="subscription">/ Annual</span>
                    </div>
                  ) : (
                    <div className="monthly_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">7</span>
                      <span className="subscription">/ Monthly</span>
                    </div>
                  )}
                </div>
                <h6 className="text-center">Perfect for small businesses</h6>
              </div>
              <div className="pricing-body mb-40 text-left">
                <ul>
                  <li>Up to 100 products</li>
                  <li>Basic support</li>
                  <li>Standard features</li>
                  <li>Email notifications</li>
                </ul>
              </div>
              <div className="pricing-btn">
                <a href="#" className="btn">
                  Start Now
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="pricing-box active text-center mb-60">
              <div className="poppuler">
                <span className="btn">Popular</span>
              </div>
              <div className="pricing-head">
                <h4>Standard</h4>
                <div className="pricing-amount">
                  {isAnnual ? (
                    <div className="annual_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">99</span>
                      <span className="subscription">/ Annual</span>
                    </div>
                  ) : (
                    <div className="monthly_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">12</span>
                      <span className="subscription">/ Monthly</span>
                    </div>
                  )}
                </div>
                <h6 className="text-center">Ideal for growing businesses</h6>
              </div>
              <div className="pricing-body mb-40 text-left">
                <ul>
                  <li>Up to 500 products</li>
                  <li>Priority support</li>
                  <li>Advanced features</li>
                  <li>Automated email notifications</li>
                </ul>
              </div>
              <div className="pricing-btn">
                <a href="#" className="btn">
                  Start Now
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="pricing-box text-center mb-60">
              <div className="pricing-head">
                <h4>Professional</h4>
                <div className="pricing-amount">
                  {isAnnual ? (
                    <div className="annual_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">149</span>
                      <span className="subscription">/ Annual</span>
                    </div>
                  ) : (
                    <div className="monthly_price">
                      <sup>
                        <span className="currency">$</span>
                      </sup>
                      <span className="price">18</span>
                      <span className="subscription">/ Monthly</span>
                    </div>
                  )}
                </div>
                <h6 className="text-center">For advanced needs</h6>
              </div>
              <div className="pricing-body mb-40 text-left">
                <ul>
                  <li>Unlimited products</li>
                  <li>Dedicated support</li>
                  <li>All features included</li>
                  <li>Customizable email notifications</li>
                </ul>
              </div>
              <div className="pricing-btn">
                <a href="#" className="btn">
                  Start Now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-60">
          <p>
            <strong>30-Day Money-Back Guarantee</strong>
          </p>
          <p>
            Not sure? Try our service risk-free. If you’re not completely
            satisfied within the first 30 days, we’ll give you a full refund.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
