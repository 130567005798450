import React from "react";

const Faqs = () => {
  return (
    <div>
      <section
        id="faqs"
        className="faq-area pb-100"
        style={{
          backgroundImage: "url(/img/shape/header-sape6.png)",
          backgroundPosition: "right center",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center pl-40 pr-40 mb-50">
                <h2>Frequently asked Questions</h2>
                <p>
                  Have questions? We’ve got answers. Explore common queries and
                  find out how our solution can work for you.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="faq-wrap">
                <div className="accordion" id="accordionExample">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              How does this Software work?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Our platform is a comprehensive solution for
                            managing your eCommerce site effortlessly. You can
                            create products, sliders, banners, and coupons
                            through our intuitive dashboard. Once you've set up
                            your store, simply copy the provided link, paste it
                            into your site, and replace your store ID. Our
                            system handles the rest, including payment
                            processing and order management.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Do I need technical knowledge to use this
                              platform?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            No, our platform is designed to be user-friendly and
                            accessible. You don't need any technical knowledge
                            to set up and manage your store. Our dashboard
                            provides easy-to-follow instructions, and you can
                            reach out to our support team if you need any
                            assistance.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              What payment methods are supported?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            We support a range of payment methods, including
                            PayPal, Stripe, and Cash on Delivery (COD). You can
                            choose the payment method that best suits your needs
                            and your customers' preferences.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              How do I integrate with my existing eCommerce
                              site?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Integration is simple. After setting up your store
                            on our platform, you'll receive a link. Paste this
                            link into your existing eCommerce site, replacing
                            your store ID. This seamless integration allows you
                            to manage your products and orders directly from our
                            dashboard.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFive">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              What types of products can I manage?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          aria-labelledby="headingFive"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            You can manage a wide variety of products, including
                            physical goods, digital items, and services. Our
                            platform also allows you to create and manage
                            sliders, banners, and promotional coupons to enhance
                            your store's appearance and functionality.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-header" id="headingSix">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Is my data secure with this platform?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSix"
                          className="collapse"
                          aria-labelledby="headingSix"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Yes, we prioritize the security of your data. Our
                            platform uses industry-standard encryption and
                            security protocols to protect your information. We
                            also perform regular security audits to ensure your
                            data remains safe.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingSeven">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Can I try free before committing to a
                              subscription?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseSeven"
                          className="collapse"
                          aria-labelledby="headingSeven"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Yes, we offer a free 14 days trial period so you can
                            explore the features and capabilities of our
                            platform before making a commitment. During the
                            trial, you'll have full access to all the features
                            to ensure it meets your needs.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingEight">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              How can I get support if I encounter issues?.
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseEight"
                          className="collapse"
                          aria-labelledby="headingEight"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Our dedicated support team is here to help you. You
                            can reach out via email, phone, or through our
                            support chat on the dashboard. We’re committed to
                            resolving any issues promptly and ensuring you have
                            a smooth experience.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingNine">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Can I customize the look and feel of my store?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseNine"
                          className="collapse"
                          aria-labelledby="headingNine"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            Absolutely. Our platform allows you to customize the
                            appearance of your store, including the layout,
                            colors, and branding elements. You can create a
                            unique shopping experience that aligns with your
                            brand identity.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTen">
                          <h2 className="mb-0">
                            <button
                              className="faq-btn collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              How do I cancel my subscription if needed?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTen"
                          className="collapse"
                          aria-labelledby="headingTen"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            If you decide to cancel your subscription, you can
                            do so through your account settings. We also provide
                            instructions on how to cancel and refund policies to
                            ensure a smooth process. If you have any issues, our
                            support team is available to assist you.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faqs;
