import React from "react";

const Testimonial = () => {
  return (
    <div>
      <section
        id="testimonios"
        className="testimonial-area testimonial-p pt-100 pb-70"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>What Our Clients Say’s</h2>
                <p>
                  Hear from Our Satisfied Clients—Real Stories of Success and
                  Exceptional Experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="testimonial-active slider">
                <div className="col-xl-4">
                  <div className="single-testimonial">
                    <div className="testi-author text-left">
                      <img
                        src="/assets/img/testimonial/testi_avatar.png"
                        alt="img"
                      />
                      <div className="ta-info">
                        <h6>Mr Jone Dose</h6>
                        <span>Author</span>
                      </div>
                    </div>
                    <div className="qutation">
                      <img
                        src="/assets/img/bg/qutation.png"
                        alt="qutation.png"
                      />
                    </div>
                    <p>
                      Great site! Easy to navigate, fast checkout, and excellent
                      customer support. Awesome developer experience!
                    </p>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="single-testimonial">
                    <div className="testi-author text-left">
                      <img
                        src="/assets/img/testimonial/testi_avatar2.png"
                        alt="img"
                      />
                      <div className="ta-info">
                        <h6>Mr Jone Dose</h6>
                        <span>Author</span>
                      </div>
                    </div>
                    <div className="qutation">
                      <img
                        src="/assets/img/bg/qutation.png"
                        alt="qutation.png"
                      />
                    </div>
                    <p>
                      Love the service and quick copying. A must-visit for all
                      online shop owners! Like the way my assets process..
                    </p>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="single-testimonial">
                    <div className="testi-author text-left">
                      <img
                        src="/assets/img/testimonial/testi_avatar3.png"
                        alt="img"
                      />
                      <div className="ta-info">
                        <h6>Mr Jone Dose</h6>
                        <span>Web Developer</span>
                      </div>
                    </div>
                    <div className="qutation">
                      <img
                        src="/assets/img/bg/qutation.png"
                        alt="qutation.png"
                      />
                    </div>
                    <p>
                      Seamless shopping experience with great deals. Highly
                      recommend for quality and service.
                    </p>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="single-testimonial">
                    <div className="testi-author text-left">
                      <img
                        src="/assets/img/testimonial/testi_avatar.png"
                        alt="img"
                      />
                      <div className="ta-info">
                        <h6>Mr Jone Dose</h6>
                        <span>Web Developer</span>
                      </div>
                    </div>
                    <div className="qutation">
                      <img
                        src="/assets/img/bg/qutation.png"
                        alt="qutation.png"
                      />
                    </div>
                    <p>
                      User-friendly site with responsive support. Products match
                      descriptions perfectly. Will shop again!
                    </p>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="single-testimonial">
                    <div className="testi-author text-left">
                      <img
                        src="/assets/img/testimonial/testi_avatar2.png"
                        alt="img"
                      />
                      <div className="ta-info">
                        <h6>Mr Jone Dose</h6>
                        <span>Web Developer</span>
                      </div>
                    </div>
                    <div className="qutation">
                      <img
                        src="/assets/img/bg/qutation.png"
                        alt="qutation.png"
                      />
                    </div>
                    <p>
                      Fantastic selection and fast delivery. Customer service is
                      top-notch. Very satisfied with my purchase.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
