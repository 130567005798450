import React from "react";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="privacy">
              <h1>Privacy Policy</h1>
              <span>
                <strong>Effective Date:</strong> 29/8/2024
              </span>

              <h3>1. Introduction</h3>
              <p>
                Welcome to cartvelly.com (“we,” “our,” or “us”). We value your
                privacy and are committed to protecting your personal
                information. This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you visit our
                website <a href="https://cartvelly.com">cartvelly.com</a>
                or use our services.
              </p>

              <h3>2. Information We Collect</h3>
              <p>We may collect the following types of information:</p>

              <h3>2.1. Personal Data</h3>
              <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Billing and shipping address</li>
                <li>Payment information (e.g., credit card details)</li>
              </ul>

              <h3>2.2. Usage Data</h3>
              <ul>
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Pages you visit</li>
                <li>Time and date of your visit</li>
                <li>Referring website</li>
              </ul>

              <h3>2.3. Cookies</h3>
              <p>
                We use cookies to enhance your experience on our platform.
                Cookies are small files stored on your device that help us
                understand your preferences and track site usage.
              </p>

              <h3>3. How We Use Your Information</h3>
              <p>We use the information we collect in the following ways:</p>
              <ul>
                <li>To process and manage your orders</li>
                <li>To communicate with you about your account or orders</li>
                <li>To improve our platform and services</li>
                <li>To personalize your experience</li>
                <li>
                  To send promotional emails or newsletters (if you opt-in)
                </li>
                <li>To comply with legal obligations</li>
              </ul>

              <h3>4. How We Share Your Information</h3>
              <p>
                We may share your information with third parties only as
                necessary to provide our services:
              </p>
              <ul>
                <li>Payment processors for transaction processing</li>
                <li>Shipping companies for order fulfillment</li>
                <li>
                  Marketing services to send promotional materials (if you
                  opt-in)
                </li>
                <li>Legal authorities when required by law</li>
              </ul>

              <h3>5. Security of Your Information</h3>
              <p>
                We use appropriate technical and organizational measures to
                protect your information. However, no method of transmission
                over the Internet or electronic storage is 100% secure, so we
                cannot guarantee absolute security.
              </p>

              <h3>6. Your Rights</h3>
              <p>You have the right to:</p>
              <ul>
                <li>Access your personal information</li>
                <li>Correct any inaccuracies</li>
                <li>Request deletion of your data</li>
                <li>Withdraw consent for data processing (where applicable)</li>
                <li>Opt-out of marketing communications</li>
              </ul>
              <p>
                To exercise these rights, please contact us at{" "}
                <a href="mailto:info@cartvelly.com">info@cartvelly.com</a>.
              </p>

              <h3>7. Third-Party Links</h3>
              <p>
                Our platform may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                external sites.
              </p>

              <h3>8. Changes to This Privacy Policy</h3>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated “Effective Date.”
              </p>

              <h3>9. Contact Us</h3>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:info@cartvelly.com">info@cartvelly.com</a>
                <br />
                <strong>Address:</strong> cartvelly.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
