import Pricing from "../components/Pricing";
import Faqs from "../components/Faqs";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Testimonial from "../components/Testimonial";
import Navigation from "../components/Navigation";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Screenshots from "../components/Screenshots";
import Steps from "../components/Steps";
import Stories from "../components/Stories";

function Homepage() {
  return (
    <div>
      <Navigation />
      <main>
        <Hero />
        <Features />
        <Stories />
        <Steps />
        <Screenshots />
        <Faqs />
        <Subscribe />
        <Pricing />
        <Testimonial />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default Homepage;
