import React from "react";

const Steps = () => {
  return (
    <div>
      <section
        id="steps"
        className="app-work pt-70 pb-100 p-relative"
        style={{
          backgroundImage: "url(/img/shape/header-sape4.png)",
          backgroundPosition: "right center",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="choose-wrap">
                <div className="section-title w-title left-align mb-15">
                  <h2>Simple Steps to Success</h2>
                </div>
                <div className="app-work-content mt-20">
                  <ul>
                    <li>
                      <div className="icon">
                        <img src="/assets/img/icon/register.png" />
                      </div>
                      <div className="text">
                        <h4>Register an Account</h4>
                        <p>
                          Sign up with your email and log in to access your
                          dashboard.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src="/assets/img/icon/cart.png" />
                      </div>
                      <div className="text">
                        <h4>Build your Store</h4>
                        <p>
                          Customize your store with sliders, banners, and
                          popups, then generate a link.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <img src="/assets/img/icon/payment.png" />
                      </div>
                      <div className="text">
                        <h4>Start Getting Paid</h4>
                        <p>
                          Insert the link into your site to begin receiving
                          orders with payments via PayPal, Stripe, or COD.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <img
                src="/assets/img/bg/app-work-img.png"
                alt="app-work-img"
                className="img"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Steps;
