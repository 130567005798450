import React from "react";

const Hero = () => {
  return (
    <div>
      <section
        id="parallax"
        className="slider-area slider-bg2 second-slider-bg d-flex fix"
      >
        <div className="slider-shape ss-eight layer" data-depth="0.50"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="slider-content second-slider-content left-center">
                <h2 data-animation="fadeInUp" data-delay=".4s">
                  Build Smarter <span>Sell Faster</span>
                </h2>
                <h4>Start Your Free 14-Day Trial Today!</h4>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                    margin: "20px 0",
                  }}
                >
                  <li style={{ marginBottom: "10px" }} className="text-black">
                    <i className="fal fa-check text-success"></i> No Credit Card
                    Required
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <i className="fal fa-check text-success"></i> Instant Access
                    to All Features
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <i className="fal fa-check text-success"></i> Elevate Your
                    eCommerce Experience
                  </li>
                </ul>

                <p data-animation="fadeInUp" data-delay=".6s">
                  Forget server setup—easily manage unlimited products and
                  enhance your eCommerce experience!
                </p>
                <div className="slider-btn mt-30 mb-30">
                  <a
                    href="https://dashboard.cartvelly.com/register"
                    className="btn ss-btn"
                    data-animation="fadeInUp"
                    data-delay=".8s"
                  >
                    Start Free Trial
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src="/assets/img/bg/hero-1.jpg"
                width="100%"
                alt="shape"
                className="s-img"
                style={{ float: "right", margin: "185px 0 0 200px" }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
