import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Pagination, EffectCoverflow } from "swiper/modules";

const Screenshots = () => {
  return (
    <div>
      <section
        id="screencasts"
        className="screen-area services-bg services-two pt-100 pb-70"
        style={{
          backgroundImage: "url(/img/shape/header-sape4.png)",
          backgroundPosition: "right center",
          backgroundSize: "auto",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="section-title text-center pl-40 pr-40 mb-50">
                <h2>Explore Our App’s Key Screens</h2>
                <p>
                  Explore our app’s sleek interface and powerful features
                  through these screenshots, demonstrating our blend of
                  functionality and elegance.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <Swiper
              modules={[EffectCoverflow, Pagination]}
              effect="coverflow"
              grabCursor={true}
              loop={true}
              centeredSlides={true}
              slidesPerView="auto"
              coverflowEffect={{
                rotate: 0,
                stretch: 20,
                depth: 150,
                modifier: 1.5,
                slideShadows: true,
              }}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <img src="/assets/img/gallery/screen-img01.png" alt="slide 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/img/gallery/screen-img02.png" alt="slide 2" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/img/gallery/screen-img03.png" alt="slide 3" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/img/gallery/screen-img04.png" alt="slide 4" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/img/gallery/screen-img05.png" alt="slide 5" />
              </SwiperSlide>
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Screenshots;
